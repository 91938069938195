import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Container, Row, Col } from 'react-grid-system';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Button from '../../elements/button';

import loadable from '@loadable/component';
const BuyButton = loadable(() => import('../../elements/shopify/buyButton'));

const classes = {
  reverseItem: {
    flexDirection: 'row-reverse',
  },
};

export default function TwoCol(props) {
  const [shopify, setShopify] = useState('closed');
  return (
    <Container className="two-col">
      <Row
        className={`flex  ${props.team ? 'team-column' : ''}`}
        style={props.reverse ? classes.reverseItem : null}
      >
        <Col md={1}>&nbsp;</Col>
        <Col
          md={5}
          className="twocol-description inline-flex flex-col justify-center"
        >
          <ReactMarkdown
            children={props.title}
            className={`twocol-title ${
              props.certification ? 'team-title' : ''
            }`}
            linkTarget="_blank"
            rel="noreferrer"
          />
          {props.certification ? (
            <ReactMarkdown
              children={props.certification}
              className="twocol-title certification"
              linkTarget="_blank"
              rel="noreferrer"
            />
          ) : null}
          <ReactMarkdown
            children={props.description}
            className="my-10"
            linkTarget="_blank"
            rel="noreferrer"
          />
          <div className={`${props.paceQuiz ? `max-w-md` : null}`}>
            {!props.mailto ? (
              !props.shopify ? (
                props.ghost ? (
                  <>
                    {props.href ? (
                      <Button
                        ghost
                        link={props.link}
                        href={props.href}
                        className="mb-5"
                      />
                    ) : null}
                  </>
                ) : (
                  <>
                    {props.href ? (
                      !props.newTab ? (
                        <Button
                          red
                          link={props.link}
                          href={props.href}
                          className="mb-5"
                        />
                      ) : (
                        <>
                          {props.addBtnName ? (
                            <>
                              <div className="flex">
                                <Button
                                  red
                                  newTab
                                  link={props.link}
                                  href={props.href}
                                />
                                <Button
                                  red
                                  newTab
                                  link={props.addBtnName}
                                  href={props.addBtnHref}
                                  customClass="ml-5"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <Button
                                red
                                newTab
                                link={props.link}
                                href={props.href}
                              />
                            </>
                          )}
                        </>
                        // <Button
                        //   red
                        //   newTab
                        //   link={props.link}
                        //   href={props.href}
                        // />
                      )
                    ) : null}
                  </>
                )
              ) : (
                <a
                  onClick={() =>
                    setShopify(shopify === 'open' ? 'closed' : 'open')
                  }
                  className={`cursor-pointer inline-flex p-6 rounded-md duration-300 justify-center border border-red-500 text-red-500 hover:bg-red-500 hover:text-white`}
                  // TODO: Change to button with style anchor tag
                  href="!#"
                >
                  Shop Skiin Products
                </a>
              )
            ) : (
              <a
                href={props.href}
                target={props.pdf ? '_blank' : '_self'}
                rel="noreferrer"
                className={`cursor-pointer inline-flex p-6 rounded-md duration-300 justify-center border border-red-500 text-red-500 hover:bg-red-500 hover:text-white`}
              >
                {props.link}
              </a>
            )}
            {props.paceQuiz ? (
              <Button
                ghost
                newTab
                link="Take the PACE quiz"
                href="https://www.onlineexambuilder.com/pace-privacy-quiz-for-connectingontario/exam-141656"
              />
            ) : null}
          </div>
        </Col>
        <Col
          md={5}
          className="twocol-content mt-16 md:mt-0 flex justify-center items-center"
        >
          <LazyLoadImage
            alt={props.link}
            src={props.image}
            className={props.team ? 'shadow-md rounded' : null}
            effect="blur"
          />
        </Col>
        <Col md={1}>&nbsp;</Col>
      </Row>
      {/* Displays Shopify Shop */}
      {props.shopify && shopify === 'open' ? (
        <div className="mt-20">
          <h3 className="text-center font-bold mb-5">
            <span className="font-bold text-red-300">Shop</span> Skiin Products
            Below
          </h3>
          <Row>
            <BuyButton />
          </Row>
        </div>
      ) : null}
    </Container>
  );
}
